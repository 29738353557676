// getters
const getters = {
  getMembershipClient: state => state.membershipClient,
  getPaymentMethod: state => state.paymentMethod,
  getPaymentData: state => {
    console.log('GETTER PAYMENT - getPaymentData')
    console.log('state.paymentMethod ---- ', state.paymentMethod)
    if (state.paymentMethod == 'CreditCard') {
      console.log('IF ::: GETTER PAYMENT - CreditCard')
      let data = {
        ...state.paymentData,
        year: state.paymentData.expirationDate
          ? state.paymentData.expirationDate.split('/')[0]
          : null,
        month: state.paymentData.expirationDate
          ? state.paymentData.expirationDate.split('/')[1]
          : null
      }
      delete data.expirationDate
      return data
    } else if (state.paymentMethod == 'DebitCard') {
      console.log('IF ::: GETTER PAYMENT - DebitCard')
      if (state.bankData.accountType == '') return state.paymentData
      return state.bankData
    } else {
      console.log('IF ::: GETTER PAYMENT - debe devolver datos vacios')
      return {}
    }
  },
  isSavedCard: state => state.paymentData.idLocal,
  getPaymentError: state => state.error,
  isTotalBarDisabled: state => state.totalBarDisabled,
  getExpirationDate: state => state.paymentData.expirationDate,
  transactions: state => state.transactions
}

export default getters
