// mutations
const mutations = {
  SET_SUBSCRIBER_ENGINE: (state, { engine }) => {
    state.engine = engine
  },
  SET_IS_LOADING: (state, { isLoading }) => {
    state.isLoading = isLoading
  },
  SET_DEPARTMENTS: (state, { departments }) => {
    state.availableDepartments = departments
  },
  SET_CITIES: (state, { cities }) => {
    state.availableCities = cities
  }
}

export default mutations
