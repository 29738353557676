import { getCookieToken } from '@/utils/appHelpers'
import axios from 'axios'
import ENVIRONMENT from '../../enviroment.json'

export const token = 'c8c303982d459a037c05b0de4250d7ff'
const userToken = getCookieToken()
const APP_ENV = process.env.VUE_APP_ENVIROMENT

export const apiClient = axios.create({
  baseURL: ENVIRONMENT[APP_ENV]['BASE_URL'] + 'api/memberships/v1',
  headers: {
    Authorization: 'Bearer ' + token,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  // timeout: 10000
})

export const membershipStripeApiClient = axios.create({
  baseURL: ENVIRONMENT[APP_ENV]['STRIPE_BASE_URL'],
  headers: {
    Authorization: 'Bearer ' + userToken,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  // timeout: 10000
})

export const membershipsAdminApiClient = axios.create({
  baseURL: ENVIRONMENT[APP_ENV]['ADMIN_BASE_URL']
})

export const apiGenericConfigClient = axios.create({
  baseURL: ENVIRONMENT[APP_ENV]['BASE_API_CONFIG_URL']
})
