export default {
  billing: {
    ivaCondition: 'condicion de IVA',
    department: 'Provincia',
    city: 'Localidad'
  },
  wizard: {
    recurrentMsg: {
      link: 'https://www.alegra.com/argentina/legal/terminos-y-condiciones/#medios'
    }
  }
}
