export default {
  general: {
    chooseAnOption: 'Choose an option',
    saveMoney: 'Save',
    save: 'save',
    cancel: 'cancel',
    add: 'add',
    edit: 'edit',
    remove: 'remove',
    successUpdatingAlert: 'Successfully updated.',
    redFieldsAlertTitle: 'Check the fields marked in red',
    redFieldsAlert:
      'We recommend that you verify your card information to continue with the purchase of your plan.',
    actualPlanAlert:
      'The plan you chose is your current plan and will automatically renew at the end of your subscription',
    errorAlert:
      'It seems that an error occurred, we are fixing it please try again later or contact support',
    validation: {
      required: 'this is a required field',
      invalidCardNumber: 'Verify that it is the correct number',
      fourDigitsYear: 'this field must contain 4 digits',
      pastYear: 'Must be after the current date',
      invalid_email: 'The email must be a valid email address.',
      invalidCardLength: 'the card number must be 16 digits',
      invalidName: 'write a valid name',
      maxLengthName: 'this field must be less than {{qty}} characters',
      minLengthName: 'this field must be greater than {{qty}} characters',
      invalidMonth: 'this month does not exist',
      invalidCVC: 'the security code must be greater than 2 digits',
      amexNotPermitted: 'we cannot process your payment with American Express',
      expiredDate: 'Must be after the current date',
      onlyNumbers: 'this field must be numeric'
    }
  },
  planPage: {
    actualPlan: 'You are currently enrolled in the plan',
    subtitle: 'Choose one of our plans and keep growing with Alegra',
    popular: 'suggested',
    otherPlan: "Still can't find the best plan for you?",
    pos: '(*) The sales amount for the POS is included in the plan limit',
    dontLikePlans: 'if limits are not your thing,',
    askUs: "we'll advise you",
    contactSupport: 'contact us',
    onlyInvoicingPlans: 'only invoicing plans',
    completePlans: 'complete plans',
    onlyInvoicing: 'only invoicing',
    choosePlan: 'choose plan',
    contactUs: 'contact us',
    month: 'month',
    months: 'months',
    verify: 'verify',
    happyWeekBanner: 'happyweek-202305-banner-en.jpg'
  },
  wizard: {
    header: {
      method: 'payment method',
      summary: 'summary',
      payment: 'payment'
    },
    footer: {
      securePaymentMethodMsg: 'Your safety is our priority, the data is encrypted by',
      back: 'Back'
    },
    paymentData: {
      header: {
        title: 'Credit or debit card',
        addNewCard: 'Enter your credit card details below to make the payment.',
        addOtherCard:
          'Enter the details of another card if you want to create a new payment method.'
      },
      fullName: 'cardholder name',
      cardNumber: 'card number',
      cardInfo: 'card information',
      expDate: 'expiration date',
      securityCode: 'security code',
      default: 'mark as favorite payment method',
      cardNamePlaceholder: 'Write the name that appears on the card',
      cardYearPlaceholder: 'yyyy',
      couponLabel: 'Do you have a promotional code?',
      couponPlaceholder: 'Promotional code',
      couponApply: 'apply',
      couponApplied: 'applied',
      couponInvalidTitle: 'Invalid promotional code',
      couponInvalid: 'The promotional code used is not valid'
    },
    bankAccountMethod: {
      bankAccountMethod: 'bank account',
      bankEntity: 'bank entity',
      accountType: 'account type',
      name: 'account holder',
      identification: "holder's identification document",
      accountNumber: 'account number',
      accountTypesOptions: {
        current: 'current account',
        savings: 'savings account',
        currentAbr: 'current account',
        savingsAbr: 'savings account'
      }
    },
    recurrentMsg: {
      part1: 'By making your payment you agree to our ',
      part2:
        '. The recurring charge for your plan will be applied automatically to your payment method and you can withdraw it at any time.',
      termsAndConditions: 'terms and conditions',
      link: 'https://www.alegra.com/en/legal/terms-and-conditions/'
    },
    paymentProcessing: 'payment processing...',
    paymentPlatforms: {
      modalSubtitle: 'select the payment method of your preference',
      otherPaymentPlatforms: 'other means of payment',
      DebitCard: {
        title: 'debit card',
        description: 'the payment will be made between 2 and 5 business days'
      },
      CreditCard: { title: 'credit / debit card', description: ' ' }
    }
  },
  totalBar: {
    title: 'suscription summary',
    frequencyTitle: 'charge recurrence',
    limitedTimeOffer: 'limited time offer',
    day: 'day',
    days: 'days',
    today: 'today',
    from: 'from',
    until: 'until',
    summary: {
      subtotal: 'subtotal',
      descuento: 'discount',
      impuestos: 'taxes',
      continue: 'continue',
      payNow: 'pay now'
    }
  },
  paymentMethod: {
    paymentMethods: 'payment methods',
    card: 'credit / debit card'
  },
  billing: {
    subtitle:
      'verify the data with which the invoice will be generated for the payment of your plan.',
    billingDetails: 'billing details',
    fullName: 'Company name / full name',
    identification: 'identification',
    personalInfo: 'personal info',
    address: 'address',
    identificationType: 'identification type',
    identificationNumber: 'identification number',
    kindOfPerson: 'kind of person',
    taxLiability: 'tax liability',
    firstName: 'first name',
    secondName: 'second name',
    lastName: 'last name',
    country: 'country',
    phone: 'phone',
    email: 'email',
    department: 'department',
    city: 'city',
    kindOfPersonOptions: {
      legalPerson: 'legal person',
      naturalPerson: 'natural person'
    },
    identificationTypeOptions: {
      NIT: 'NIT - Tax identification number',
      CC: 'CC - Citizenship card',
      DIE: 'DIE - Foreign Identification Document',
      PP: 'PP - Passport',
      CE: 'CE - Foreigner Identification Card',
      TE: 'TE - Immigration card',
      IT: 'IT - Identity card',
      RC: 'RC - Civil Registry',
      NITOtherCountry: 'NIT of another country - Identification of another country',
      NUIP: 'NUIP - Unique Personal Identification Number'
    },
    regimesOptions: {
      responsibleForVAT: 'Responsible for VAT',
      notResponsibleForVAT: 'Not responsible for VAT',
      nationalConsumptionTax: 'National Consumption Tax - INC',
      notResponsibleForINC: 'Not responsible for INC',
      responsibleForVATAndINC: 'Responsible for VAT and INC',
      specialRegime: 'Special regime'
    }
  },
  frequencyCharge: {
    anual: 'anual',
    biannual: 'biannual',
    mensual: 'mensual'
  },
  confirmationPage: {
    choosenPlan: 'choosen plan'
  },
  receiptPage: {
    status: 'Successful payment!',
    statuses: {
      changeInProgress: 'Change in progress!',
      successfulChange: 'Successful change!',
      paymentInProgress: 'Payment in progress!',
      successfulPayment: 'Successful payment!'
    },
    newSubscription: {
      title: {
        part1: 'Your ',
        part2: '{{plan}} Plan of {{product}} ',
        part3: 'is now active! 🚀'
      },
      subtitle: {
        tc: {
          part1: 'Thank you for making the decision to ',
          part2Ac: 'give your business superpowers. ',
          part2Ne: 'issue your payroll in record time. ',
          part2Pos: 'invoice quickly and easily. ',
          part2Shop: 'keep your business online. ',
          part3: 'You will receive your invoice very soon in the email of the administrator user.'
        },
        debit: {
          part1: 'We will make your payment during the ',
          part2: 'next 3 business days ',
          part3:
            'and as soon as it becomes effective you will receive your invoice in the email of the administrator user.'
        }
      }
    },
    upgrade: {
      title: {
        part1: 'We are excited to know that ',
        part2: 'your business continues to grow! 🙌'
      },
      subtitle: {
        part1: 'Your {{plan}} Plan of {{product}} ',
        part2: 'is now active ',
        part3Card: 'and we already charge for the additional value of your new plan.',
        part3Debit: 'and we will charge for the additional value during the next 5 business days.'
      }
    },
    downgrade: {
      title: {
        part1: 'New plan ',
        part2: 'successfully selected! 🙌'
      },
      subtitle: {
        part1: 'Your {{plan}} Plan of {{product}} ',
        part2: 'will be active from your next payment, ',
        part3: 'where we will charge for the value of this new plan.'
      }
    },
    invoiceNumber: 'invoice #',
    // sendedTo: 'we have sent the payment invoice to the mail',
    sendedTo:
      'Thank you for making the decision to give your business superpowers. You will receive your invoice very soon in the email of the administrator user.',
    goToSuscription: 'view subscription',
    thanks: 'Thanks for your purchase!',
    makeUseOfAlegra: 'Your {{plan}} Plan is now active!'
  },
  planNames: {
    pyme: 'pyme',
    pro: 'pro',
    plus: 'plus',
    entrepreneur: 'entrepreneur',
    emprendedor: 'entrepreneur',
    corporativo: 'corporativo',
    free: 'free',
    gratis: 'free'
  },
  products: {
    alegraWeb: 'Accounting',
    alegraNe: 'Payroll',
    alegraPos: 'POS',
    alegraShop: 'Shop'
  }
}
