import { membershipStripeApiClient, apiClient, token } from '@/services/index'
import store from '@/store'
export default {
  async getSuscription(idCompany, product) {
    const subscriberResponse = await this.getSubscriber(idCompany)
    const subscriber = subscriberResponse.data

    let url = `/customers/${subscriber.idStripeClient}?idCompany=${idCompany}&token=${token}`

    if (!subscriber.idStripeClient) {
      const userMail = store.getters['session/userMail']
      const company = store.getters['session/company']
      const companyName = company?.name ?? ''
      const appVersion = store.getters['app/appVersion']
      url += `&customerEmail=${encodeURIComponent(userMail)}`
      url += `&customerName=${encodeURIComponent(companyName)}`
      url += `&appVersion=${appVersion}`
    }
    const response = await membershipStripeApiClient.get(url)
    await store.dispatch('payment/setMembershipClient', response.data.customer)

    return apiClient.get('/subscribers/' + idCompany + '/subscriptions/current?product=' + product)
  },
  getAvailablePlans(idCompany, product, lang, currency, appVersion, recurringInterval = 'month') {
    return membershipStripeApiClient.get(
      `/plans?currency=${currency}&product=${product}&recurringInterval=${recurringInterval}&companyId=${idCompany}&lang=${lang}&appVersion=${appVersion}`
    )
  },
  async verifyCoupon(idCompany, promotionCode, plan, recurringInterval = 'month') {
    const discount = store.getters['plan/getDiscount']

    const response = await membershipStripeApiClient.get(
      `/coupons/${promotionCode}?idProduct=${plan.productId}&recurringInterval=${recurringInterval}`
    )
    const coupon = response.data.results
    if (!coupon.isValid) return coupon
    let amount = 0
    if (coupon.discount.percent) {
      amount = (coupon.discount.percent * (plan.price - discount)) / 100
    } else {
      amount = coupon.discount.amount
    }
    coupon.discount.total = amount
    store.dispatch('plan/setCoupon', coupon)
    return coupon
  },
  async getPreview(customerId, plan, currency, couponId, recurringInterval = 'month') {
    let couponParam = couponId ? `&couponId=${couponId}` : ''
    const response = await membershipStripeApiClient.get(
      `/subscription-preview?currency=${currency}&idProduct=${plan.productId}${couponParam}&recurringInterval=${recurringInterval}&customerId=${customerId}`
    )
    const preview = response.data.results
    return preview
  },
  getSubscriber(idCompany) {
    return apiClient.get('/subscribers/' + idCompany)
  },
  async getPlanDetail(
    idCompany,
    idPlan,
    lang,
    currency,
    product,
    appVersion,
    recurringInterval = 'month'
  ) {
    const response = await membershipStripeApiClient.get(
      `/plans/${idPlan}?currency=${currency}&recurringInterval=${recurringInterval}&companyId=${idCompany}&lang=${lang}&product=${product}&appVersion=${appVersion}`
    )
    store.dispatch('plan/setProductId', response.data.results.productId)

    return response
  },
  getPaymentPlatforms(idCompany) {
    return apiClient.get('/subscribers/' + idCompany + '/payment/platforms')
  },
  async getSavedPaymentMethods(idCompany) {
    let customer = store.getters['payment/getMembershipClient']
    if (!customer) await this.getSuscription(idCompany, store.getters['webComponent/getProduct'])
    customer = store.getters['payment/getMembershipClient']

    return membershipStripeApiClient.get(`/customers/${customer.id}/payment-methods`)
  },
  getBillingInfo(idCompany) {
    return apiClient.get('/subscribers/' + idCompany + '/billing')
  },
  setBillingInfo(idCompany, data) {
    return apiClient.post('/subscribers/' + idCompany + '/billing', data)
  },
  subscribe(idCompany, data) {
    return membershipStripeApiClient.post('/subscriptions', { ...data, idCompany })
  },
  changeDefaultPayment(idCompany, idLocal) {
    return apiClient.post('/subscribers/' + idCompany + '/payment/methods/change-default', {
      idLocal
    })
  }
}
