import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const initialState = () => ({
  engine: '',
  availableDepartments: [],
  availableCities: [],
  isLoading: false
})

const state = initialState()

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
