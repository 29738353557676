import { apiGenericConfigClient } from '@/services/index'

export default {
  getDepartments(appVersion) {
    return apiGenericConfigClient.get('v1/departments', {
      params: {
        application_version: appVersion
      }
    })
  },
  getCities(appVersion) {
    return apiGenericConfigClient.get('v1/cities', {
      params: {
        application_version: appVersion
      }
    })
  }
}
